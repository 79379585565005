<template>
  <div v-if="analyticsData">
    <b-row class="d-flex align-items-center">
      <b-col cols="12">
        <AnalyticsDateSelector
          title="DELIVERY STATUS DASHBOARD"
          :is-loading="isLoading"
          @handleExtract="handleExtract"
          @handleExport="handleExport"
        />
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col
            cols="12"
            lg="12"
          >
            <b-card>
              <b-row>
                <b-col
                  v-for="(statistic, index) in firstStatuses"
                  :key="index"
                  cols="12"
                  lg="3"
                >
                  <StatisticsCard
                    :statistics="statistic"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col
                  v-for="(statistic, index) in secondStatuses"
                  :key="index"
                  cols="12"
                  lg="3"
                >
                  <StatisticsCard
                    :statistics="statistic"
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col
                  v-for="(statistic, index) in thirdStatuses"
                  :key="index"
                  cols="12"
                  lg="6"
                >
                  <StatisticsCard
                    :statistics="statistic"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="12"
          >
            <b-card>
              <AnalyticsLineGraph
                v-if="!isFetchingGraph"
                :title="'Delivery Status'"
                :chart-data="graphData"
                :selected-filter-by="filterBy"
                @filterGraphBy="filterGraphBy"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go back
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  StatisticsCard, AnalyticsLineGraph, AnalyticsDateSelector,
} from '@/components'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'DeliveryStatusAnalytics',
  components: {
    StatisticsCard,
    AnalyticsLineGraph,
    AnalyticsDateSelector,
  },
  mixins: [analytics],
  data() {
    return {
      firstStatuses: [],
      secondStatuses: [],
      thirdStatuses: [],
      type: 'delivery_status',
      hasGraph: true,
    }
  },
  watch: {
    analyticsData(newValue) {
      this.firstStatuses = newValue.firstStatuses
      this.secondStatuses = newValue.secondStatuses
      this.thirdStatuses = newValue.thirdStatuses
    },
  },
}
</script>
